<template>
  <div>
    <!-- Header -->
    <b-row
      align-h="between"
      align-v="center"
      class="py-2"
    >
      <b-col
        md="6"
        lg="6"
      >
        <!-- Page title -->
        <h2 class="mb-2 mb-md-0">
          LED
        </h2>
      </b-col>
      <b-col
        class="mb-1 mb-md-0"
        md="3"
        lg="3"
      >
        <label for="ts">Tahun Semester :</label>
        <v-select
          id="ts"
          v-model="ts"
          :reduce="option => option.value"
          :options="tsOptions"
          class="bg-white w-100"
          @input="onTSChange"
        >
          <span slot="no-options">Operator belum ada yang mengisi profil.</span>
        </v-select>
      </b-col>
      <b-col
        md="3"
        lg="3"
        class="mb-1 mb-md-0"
      >
        <label for="ts">Program Studi :</label>
        <v-select
          id="department"
          v-model="department"
          :reduce="option => option.value"
          :options="departmentOptions"
          class="bg-white w-100"
          @input="onDepartmentChange"
        />
      </b-col>
    </b-row>

    <!-- Table -->
    <b-card body-class="p-0">
      <!-- Header -->
      <b-card-header>
        <h4 class="mb-0">
          Daftar Tahun Semester
        </h4>
      </b-card-header>

      <!-- No Data -->
      <template #empty>
        <p class="mb-0 py-2 text-center">
          <span class="d-block">Tidak ada data per tahun semester, silakan hubungi operator untuk membuat led sesuai tahun semester.</span>
        </p>
      </template>

      <!-- Body -->
      <b-table
        class="m-0"
        responsive="sm"
        :items="leds"
        :fields="fields"
      >
        <template #cell(action)="data">
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="action-button mr-1"
              :to="{ name: 'rev-led-form', params: { id: data.item.id } }"
              size="sm"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
        <template #cell(status)="data">
          <b-badge :variant="statusVariantBadge(data.item.status || 'Tidak ada status')">
            <strong>{{ data.item.status ||'Tidak ada status' }}</strong>
          </b-badge>
        </template>
      </b-table>

      <!-- Footer -->
      <b-card-footer>
        <b-row
          align-h="between"
          align-v="center"
        >
          <b-col
            sm="2"
            class="mb-2 mb-sm-0"
          >
            <!-- Select amount of data per page -->
            <b-form-select
              v-model="pagination.selectedAmount"
              :options="pagination.amountOptions"
            />
          </b-col>
          <b-col
            col
            class="d-flex justify-content-center justify-content-sm-end"
          >
            <!-- Pagination -->
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalItems"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              :per-page="pagination.selectedAmount"
            />
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import OverlayLoading from '@/views/components/OverlayLoading.vue'

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      isLoading: true,
      fields: [
        { key: 'number', label: '#' },
        { key: 'ts', label: 'Tahun Semester', sortable: true },
        { key: 'user.departments[0].program_studi', label: 'Program Studi', sortable: true },
        { key: 'user.departments[0].lkps_program.jenis_program', label: 'Jenjang', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'action', label: 'Aksi' },
      ],
      leds: [],

      pagination: {
        amountOptions: [5, 10, 25, 50, 100],
        selectedAmount: 10,
        totalItems: 0,
        currentPage: 1,
      },

      ts: '',
      department: [],
      tsOptions: [],
      departmentOptions: [],
    }
  },
  computed: {
    statusVariantBadge() { return any => this.$variantBadge[any] },
  },
  created() {
    this.fetchTSOptions()
    this.fetchDeparmentOptions()

    this.fetch()
  },
  methods: {
    fetch() {
      this.isLoading = true
      const { id } = getUserData()

      console.log(this.department, this.ts)

      useJwt.http.get('list_led', {
        params: {
          page: this.pagination.currentPage - 1,
          size: this.pagination.selectedAmount,
          sort: 'ASC',
          ts: this.ts,
          program_studi: this.department ? this.department[0] : '',
          short_name: this.department ? this.department[1] : '',
        },
      })
        .then(response => {
          const number = ((this.pagination.currentPage * this.pagination.selectedAmount) - this.pagination.selectedAmount) + 1
          this.leds = response.data.list_leds
            .map((led, index) => ({
              number: index + 1, ...led, status: led.status || 'Tidak ada status', action: '',
            }))
          this.pagination.totalItems = response.data.total_items
        })
        .catch(err => {
          console.log(err)
          this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' })
        })
        .finally(() => this.isLoading = false)
    },
    fetchTSOptions() {
      useJwt.http.get('ts_options')
        .then(response => {
          this.tsOptions = response.data.ts_options.map(ts => ({ label: ts.replace('/', ' / '), value: ts }))
        })
    },
    fetchDeparmentOptions() {
      useJwt.http.get('department', {
        params: {
          size: 555,
        },
      })
        .then(response => {
          this.departmentOptions = response.data.departments.map(department => ({
            label: `${department.program_studi} (${department.lkps_program.short_name})`,
            value: [department.program_studi, department.lkps_program.short_name],
          }))
        })
    },
    onTSChange(value) {
      this.ts = value
      this.$store.commit('app/UPDATE_REVIEWER_INFO', { ...this.$store.state.app.reviewer, ts: value })
      this.fetch()
    },
    onDepartmentChange(value) {
      this.department = value
      this.$store.commit('app/UPDATE_REVIEWER_INFO', { ...this.$store.state.app.reviewer, department: value })
      this.fetch()
    },
  },
}
</script>
